import React, { useEffect } from "react";
import { useState } from "react";
import "../scss/ambassador.scss"

import OS from "../img/ambassador/piple/ОлегСкрипка.png"
import OSBee from "../img/ambassador/piple/ОлегСкрипка_bee.png"
import AC from "../img/ambassador/piple/AndrewChumachenko.png"
import ACBee from "../img/ambassador/piple/AndrewChumachenko_bee.png"
import DBBee from "../img/ambassador/piple/DorjeBatuu_bee.png"
import DB from "../img/ambassador/piple/DorjeBatuu.png"
import EK from "../img/ambassador/piple/ElenaKorobkova.png"
import EKBee from "../img/ambassador/piple/ElenaKorobkova_bee.png"
import KM from "../img/ambassador/piple/KirillMankovski.png"
import KMBee from "../img/ambassador/piple/KirillMankovski_bee.png"
import CS from "../img/ambassador/piple/ClaudineSchooley.png"
import CSBee from "../img/ambassador/piple/ClaudineSchooley-bee.png"
import VI from "../img/ambassador/piple/ValeriyIakovenko.png"
import VIBee from "../img/ambassador/piple/ValeriyIakovenko_bee.png"
import KH from "../img/ambassador/piple/KurylenkoHeorhii.png"
import KHBee from "../img/ambassador/piple/KurylenkoHeorhii_bee.png"
import KS from "../img/ambassador/piple/KonstantinSoloviov.png"
import KSBee from "../img/ambassador/piple/KonstantinSoloviov_bee.png"
import VP from "../img/ambassador/piple/VolodymyrPylypenko.png"
import VPBee from "../img/ambassador/piple/VolodymyrPylypenko-bee.png"
import VG from "../img/ambassador/piple/VictorGalchynsky.png"
// import KMBee from "../img/ambassador/piple/KirillMankovski_bee.png"
import VB from "../img/ambassador/piple/ViraBondarenko.png"
import VBBee from "../img/ambassador/piple/ViraBondarenko_bee.png"
import LO from "../img/ambassador/piple/LymanskaOksana.png"
import LOBee from "../img/ambassador/piple/LymanskaOksana_bee.png"
import AB from "../img/ambassador/piple/AnastasiiaBereziuk.png"
import ABBee from "../img/ambassador/piple/AnastasiiaBereziuk_bee.png"


import ArrowR from "../img/ambassador/arrow-right.svg"
import ArrowL from "../img/ambassador/arrow-left.svg"
import CloseX from "../img/ambassador/close.svg"

const LengA = {
    "ua":{
        "title":"ambassador",
        "sub-title":"Ми створили унікальну благодійну ініціативу, яка об’єднує амбасадорів з добрими, турботливими серцями та щирою любов’ю до дітей. Приєднуйтесь до нас, щоб поділитися нашим проектом з найбільшою аудиторією в усьому світі. Разом ми допоможемо забезпечити ліками та вітамінами більше українських дітей.",
        "url":"https://forms.gle/92c8HQuyypBjsHqk7"
    },
    "en":{
        "title":"ambassador",
        "sub-title":"We have created a unique charity initiative that unites ambassadors with kind, caring hearts and sincere love for children. Join us to share our project to the biggest audiences across the world. Together we will help supply medicine and vitamins to more Ukrainian children",
        "url":"https://forms.gle/akbp9wUNZsGhj8u19"
    }
}

const AmbassadorArray = [
    [OS,OSBee,"Oleg Skrypka","Ukranian musician, singer, composer"],
    [AC,ACBee,"Andrew Chumachenko","Co-founder in Netpeak"],
    [DB,DBBee,"Dorje Batuu","Ukrainian writer"],
    [EK,EKBee,"Elena Korobkova","Head of the NABU Council"],
    [KM,KMBee,"Kirill Mankovski","CEO / MD at robota.ua"],
    [VI,VIBee,"Valeriy Iakovenko","Founder in DroneUA"],
    [KS,KSBee,"Konstantin Soloviov","Founder & CEO - Soloviov marketing agency"],
    [KH,KHBee,"Kurylenko Heorhii","Event marketer at IT STEP Academy Odesa"],
    [VG,VG,"Victor Galchynsky","Chief communications officer in KredoBank"],
    [VB,VBBee,"Vira Bondarenko","Head of Marketing Support Department of Transaction Business in Tascombank",],
    [VP,VPBee,"Volodymyr Pylypenko","President Charter Rotary Kyiv Balzac Ukraine"],
    [CS,CSBee,"Claudine Schooley","President Inter comity countries Rotary USA, Ukraine."],
    [LO,LOBee,"Lymanska Oksana","Investment consultant."],
    [AB,ABBee,"Anastasiia Bereziuk","OncoPsychologist"]
]

function AbassadorPopUp(){
    const [close, setClose] = useState(false)
    const [L, setL] = useState("en")
    useEffect(()=>{

        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setL(el.target.id)
            })
        })

        document.querySelector(".ambassadir_view").addEventListener("click",()=>{
            setClose(!close)
        })
        
        window.addEventListener("keydown",(event)=>{
            if(event.key === "Escape"){
                setClose(false)
            }
        })
    })

    return(
        <div className={!close? "ab-pop-up ab-pop-up__dis":"ab-pop-up"}>
            <img src={CloseX} alt="" className="ab-pop-up_close" onClick={()=>{setClose(!close)}}/>
            <span className="ab-pop-up_title title">our ambassadors</span>
            <span className="subtitle">{LengA[L]["sub-title"]}</span>
            <a href={LengA[L]["url"]} target="_blank" rel="noreferrer"><button className="button-location">join to ambassador</button></a>

            <div className="case-pop-up-ambass">
                <AmbassadorCase color="yello"></AmbassadorCase>
            </div>
        </div>
    )
}

function AmbassadorCase(prop){
    const [color, setColor] = useState(prop.color)
    // const [bee, setBee] = useState(false)

    return(
        AmbassadorArray.map((el,index)=>{
            return(
                <div className="amba-case" key={index}>
                    <div className="case-amba" >
                        <div className={`amba-case_border amba-case_border__${color}`}></div>
                        <img src={el[1]} className={"amba-case_bee"} alt ="ambassador"/>
                        <img src={el[0]} className="amba-case_h" alt ="ambassador"/>                        
                    </div>
                    <div className="amba-case_info-case">
                        <span>{el[2]}</span>
                        <span>{el[3]}</span>
                    </div>
                </div>
            )
        })
    )
}

function Carousel({children}){
    const [pos, setPos] = useState(0)
    const [width, setWidth] = useState(306)
    const [widthAll, setWidthAll] = useState(0)
    const [widthBlock, setWidthBlock] = useState(0)
    const [blocks, setBlocks] = useState(0)

    const step = () => {
        setWidth(document.querySelector(".amba-case").offsetWidth)
    }

    const style ={
        transform:`translateX(${pos}px)`
    }

    const AllBlock = () => {
        const item = document.querySelector(".all-item")
        setWidthBlock(width*item.children.length)

    }

    function resize (){
        if(window.innerWidth >= "1301"){
            setBlocks(4)
        }else if(window.innerWidth <= "1300" && window.innerWidth >= "1005"){
            setBlocks(3)
        }else if(window.innerWidth <= "1004" && window.innerWidth >= "700"){
            setBlocks(2)
        }else if(window.innerWidth <= "699"){
            setBlocks(1)
        }

        setWidthAll(width * blocks)

        if(pos < widthAll-widthBlock){
            setPos(widthAll-widthBlock)
            
        }
        // console.log("resize")
    }


    const nextStep = (modi) =>{
        if (modi === "-"){
            if(pos > widthAll-widthBlock){
                setPos(pos-width)
            }else if(pos <= widthAll-widthBlock){
                setPos(0)
            }

        }else if(modi === "+"){
            if(pos < 0){
                setPos(pos+width)
            }else {
                setPos(-widthBlock)
            }
        }
        // console.log(widthBlock)
    }

    useEffect(()=>{
        step()
        AllBlock()
        resize()
        window.addEventListener("resize",()=>{
            setWidthAll()
            resize()
        })
    })


    return(
        <div className="car-main-cont">
            <img src={ArrowL} alt="" onClick={()=>{nextStep("+")}}/>
            <div className="window">
                <div className="all-item" 
                    style={style}>
                    <AmbassadorCase color="blue"></AmbassadorCase>
                </div>
            </div>
            <img src={ArrowR} alt="" onClick={()=>{nextStep("-");console.log(pos)}}/>
        </div>
    )
}



export function Ambassador(){

    const [L, setL] = useState("en")

    useEffect(()=>{
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setL(el.target.id)
            })
        })
    })


    return(
        <div className="ambassador">
            <div className="ambassador_title title ">{LengA[L]["title"]}</div>
            <Carousel></Carousel>
            <button className="ambassadir_view">view all</button>
            <AbassadorPopUp></AbassadorPopUp>
            <a href={LengA[L]["url"]} target="_blank" rel="noreferrer"><button className="button-location">join to ambassador</button></a>
        </div>
    )
}

