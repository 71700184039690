import React, { useEffect, useState } from "react";

import "../scss/about-us.scss"
import { FlowerToken } from "./modail";
import arrow from "../img/modail/arrow-tile0.png"
import { Partner } from "./partner";
import HeardCity from "../img/modail/heartcity0.png"

import bee0 from "../img/animation/1.png"
import bee1 from "../img/animation/2.png"
import bee2 from "../img/animation/3.png"
import bee3 from "../img/animation/4.png"
import bee4 from "../img/animation/5.png"
import bee5 from "../img/animation/7.png"
import bee6 from "../img/animation/8.png"
import bee7 from "../img/animation/9.png"
import bee8 from "../img/animation/11.png"
import bee9 from "../img/animation/12.png"
import bee10 from "../img/animation/13.png"
import bee11 from "../img/animation/14.png"
import bee12 from "../img/animation/15.png"
import bee13 from "../img/animation/17.png"
import bee14 from "../img/animation/18.png"
import bee15 from "../img/animation/20.png"
import bee16 from "../img/animation/21.png"
import bee17 from "../img/animation/22.png"
import bee18 from "../img/animation/23.png"
import bee19 from "../img/animation/24.png"
import bee20 from "../img/animation/25.png"
import bee21 from "../img/animation/27.png"
import bee22 from "../img/animation/28.png"
import bee23 from "../img/animation/29.png"
import bee24 from "../img/animation/30.png"
import bee25 from "../img/animation/32.png"
import bee26 from "../img/animation/33.png"
import bee27 from "../img/animation/34.png"
import bee28 from "../img/animation/36.png"
import bee29 from "../img/animation/37.png"
import bee30 from "../img/animation/38.png"
import bee31 from "../img/animation/39.png"

const lengArr = {
    "en":{
        'about-us':{
            'nft-info':"Bee Ukranian",
            'nft-info0':" is a collection of 10,000 unique",
            'nft-imfo01':` NFT of Ukrainians and their friends who have teamed up to save Ukraine.`,
            'nft-info1':"With over 400 variants and endless combinations of features, each NFT contains rare and unique illustrations that are stored as a token. By buying our bees, you help us to provide medicine and vitamins to children in Ukraine.",
            'nft-info2':"Token Standard: ERC-1155 Blockchain: Polygon Metadata: Centralized ",
            // 'nft-info3':"Придбання капсули коштуватиме 0,08 ETH за кожну і буде обмежено для кожної адреси. Володіння NFT надасть вам доступ до всіх майбутніх подій у додатку. Умови власності покриваються ліцензією CC BY-SA 4.0."
        },
        'flower-token':{
            0:["Courage ","We are inspired by the heroism of Ukrainians and we aim for our ambitious goals to follow in their footsteps."],
            1:["Cooperation and unity","we combine the efforts of volunteers, artists and partners from various fields of expertise as an effective way to overcome all obstacles to achieve our overarching goals."],
            2:["Transparency"," Openness and clarity are an integral part of our approach because we want everyone to be able to transparently monitor everyone's contribution and how it helps Ukraine."],
        },
        'info':[
            
            ['Mission','Our main interest is to spread information about the heroism of every Ukrainian and help children to survive in this struggle for the forces of good for our freedom.'],
            ["Vision",'Bee Ukrainian brings together local creativity and global opportunities to raise $1,000,000 to save the lives of young and old Ukrainians, increase justice and ensure a peaceful future.']
        ]
    },
    "ua":{
        'about-us':{
            'nft-info':"Bee Ukranian",
            'nft-info0':" — це колекція з 10 000 унікальних ",
            'nft-imfo01':`NFT — цифрової версії українців та їх друзів, які об'єдналися, щоб вберегти Україну від ворога.`,
            'nft-info1':"Маючи понад 400 варіантів і нескінченні комбінації ознак, кожна NFT містить рідкісні та унікальні ілюстрації, які зберігаються як токен. Купуючи наших бджіл, ви допомагаєте передавати дітям ліки та вітаміни в Україні.",
            'nft-info2':"Token Standard: ERC-1155 Blockchain: Polygon Metadata: Centralized",
            // 'nft-info3':"Придбання капсули коштуватиме 0,08 ETH за кожну і буде обмежено для кожної адреси. Володіння NFT надасть вам доступ до всіх майбутніх подій у додатку. Умови власності покриваються ліцензією CC BY-SA 4.0."
        },
        'flower-token':{
            0:["Відвага","Ми натхненні героїзмом українців, тому ставимо перед собою такі ж амбіційні цілі"],
            1:["Співпраця та єдність","Ми об’єднуємо зусилля волонтерів, художників та партнерів з різних сфер, як ефективний спосіб подолати усі перешкоди та спільними зусиллями досягнути однієї мети."],
            2:["Прозорість","Відкритість та зрозумілість є невід’ємною частиною нашого підходу, тому ми хочемо, аби кожен міг прозоро відслідковувати як його внесок допоміг українцям."],
        },
        'info':[
            ['Місія','Наш основний інтерес – поширити інформацію про героїзм кожного українця та допомогти вистояти у цій боротьбі за нашу свободу.'],
            ["Візія",'Bee Ukrainian об’єднує локальну творчість та глобальні можливості, аби залучити $1,000,000 на порятунок життів маленьких та великих українців, підвищення справедливості та забезпечення мирного майбутнього.']
        ]
    }
}



function WhatIsYPIAU(){
    return(
        <div className="i-am-ukrainian">
            <span className="i-am-ukrainian_title title">What is your POWER? <br/>I am UKRAINIAN</span>

            <div className="city-heart">
                <img className="city-heart_img" src={HeardCity} alt="" />
                <img className="city-heart_arrow"src={arrow} alt="" />
            </div>
        </div>
    )
}


const beeArr = [bee0,bee1,bee2,bee3,bee4,bee5,bee6,bee7,bee8,bee9,bee10,bee11,bee12,bee13,bee14,bee15,bee16,bee17,bee18,bee19,bee20,bee21,bee22,bee23,bee24,bee25,bee26,bee27,bee28,bee29,bee30,bee31]

export function AboutUs(){
    const [leng, setLeng] = useState("en")
    const [count, setCount] = useState(0)
    
    useEffect(()=>{        
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })

        const interval = setInterval(() => {
            Array.prototype.slice.call(document.querySelector(".nft-treal_visual").children).map((el)=>{
                return el.style.display ="none"
            })
            document.querySelector(".nft-treal_visual").children[0].style.display="block"
            if(count <= 31){
                setCount(count+1)
                document.querySelector(".nft-treal_visual").children[count].style.display="block"
                
            }else{
                setCount(0)
            }
        }, 400);
          

        return () => clearInterval(interval);
    },)
  
    

    return(
        <div className="about-us">
            <div className="about-us_backgound"></div>
            <span className="about-us_title title">About us</span>
            <div className="about-us_info-case info-case">
                <div className="info-case_nft-treal nft-treal">
                    <div className="nft-treal_visual">
                        {
                            beeArr.map((el,index)=>{
                                return <img src={el} alt="" style={{display:"none"}} key={index} />
                            })
                        }
                    </div>
                </div>
                <div className="info-case_nft-info nft-info">
                    <span className="nft-info_title-sub">
                        <span className="nft-info_title">{`${lengArr[leng]['about-us']["nft-info"]}`}</span>
                        {`${lengArr[leng]['about-us']["nft-info0"]}`}
                        <br /><span className="nft-info_title-sub">{`${lengArr[leng]['about-us']['nft-imfo01']}`}</span>
                        
                    </span>
                    <span className="nft-info_title-sub">{`${lengArr[leng]['about-us']["nft-info1"]}`}</span>
                    <span className="nft-info_title">{`${lengArr[leng]['about-us']["nft-info2"]}`}</span>
                </div>
                
            </div>
            <div className="about-us_info">
                <div className="case-yellow">
                    <span>{lengArr[leng]["info"][0][0]}</span>
                    <span>{lengArr[leng]["info"][0][1]}</span>
                </div>
                
                <div className="case-yellow">
                    <span>{lengArr[leng]["info"][1][0]}</span>
                    <span>{lengArr[leng]["info"][1][1]}</span>
                </div>
            </div>
            <div className="about-us_token-info">
                <FlowerToken>{lengArr[leng]['flower-token'][0]}</FlowerToken>
                <FlowerToken>{lengArr[leng]['flower-token'][1]}</FlowerToken>
                <FlowerToken>{lengArr[leng]['flower-token'][2]}</FlowerToken>
            </div>
            <Partner></Partner>
            <WhatIsYPIAU></WhatIsYPIAU>
        </div>
    )
}

