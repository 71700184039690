import React from "react";

import Opensea from '../img/sosial/opensea.svg'
import Discord from '../img/sosial/discord.svg'
import Facebook from '../img/sosial/facebook.svg'
import Instagram from '../img/sosial/Instagram.svg'
import Twiter from '../img/sosial/twiter.svg'
import Linkin from '../img/sosial/linkin.svg'



export function SosialBox(){

    const linkArry = [
        [Opensea,'https://opensea.io/collection/beeukrainian'],
        [Discord,"https://discord.gg/zRMSv3cAJR"],
        [Instagram,"https://www.instagram.com/beeukrainian/"],
        [Twiter,"https://twitter.com/UkrainianBee"],
        [Linkin,"https://www.linkedin.com/company/beeukrainian"]
    ]

    return ( 
        <div className="sosial-box">
            {
                linkArry.map((el,index)=>{
                    return(
                        <a href={el[1]} target="_blank" className="sosial-link" key={index}>
                            <img src={el[0]} alt="" />
                        </a>
                    )
                })
            }
        </div>
    )
}