import React, { useEffect, useState } from "react";
import "../scss/roadmap.scss"
import Flower0 from "../img/roadmap/flower_1.png"
import Rectangle from "../img/roadmap/Rectangle.png"
import bee0 from "../img/roadmap/bee.png"
import bee1 from "../img/roadmap/bee1.png"
import bee2 from "../img/roadmap/bee2.png"
import { Ambassador } from "./ambassador";
// import {ButtonLocation} from "./button";


const lengArr = {
    "en":{
        "roadmap":[
            ["Pre-Mint",["Build the website, Discord, Twitter","Complete verification with a reputable regulated cryptocurrency exchange","Active marketing campaign to ensure the longevity of our project","Giveaway free starter NFTs"]],
            ["Ambassadors",["We invite the first 100 ambassadors to the project - companies, famous people, influencers, marketers, PR people and other bees"]],
            ["Public Sale",["Public sale starts on 12.12.2022 (prices starting from 1.11 Matic)","10,000 NFTs sold will allow us to launch a charity marketplace to help Ukraine"]],
            ["Charitable Purpose",["90% of the revenue will be donated to a charitable organization “Ukrainian Educational Foundation” for the purchase of medicine and vitamins for children in Ukraine.","10% of the revenue will be directed to the development of our charity platform Charity Hero as well as other collections we are currently working on."]],
            "This roadmap outlines our main goals to achieve. We have plenty of ideas for the development of our community and the concepts we are working on. Step by step we are evolving and improving the project."
        ],
        "roadmapBlock":[
            [
                [bee0,"STEP ONE","Download and set up Metamask. Metamask is a secure application that can generate an Polygon ( Matic)  wallet for you, store your Matic (or ETH-based cryptocurrencies) as well as your NFTs and allow you to transact on the Polygon blockchain.","Metamask","https://metamask.io/"],
                [bee1,"STEP TWO","To buy a Ukrainian Bee NFT you will need to add Matic (Polygon) to your wallet. This can be done by using an exchange or broker like Coinbase or Binance, to convert your Fiat currency (USD/EUR/GBP etc.) to Matic (Polygon) and then withdrawing the Matic to your wallet on Metamask.","Coinbase","https://www.coinbase.com/"],
                [bee2,"STEP THREE","You can purchase Ukrainian Bees NFTs by clicking the “Mint” button below. Public sale starts on 12.12.2022 (prices starting from 1.11 Matic). After minting, the collection will be available on the OpenSea.io platform. Each NFT will provide access to other special features ​in our community.","Mint",,"mint"],
            ]
        ]
    },
    "ua":{
        "roadmap":[
            ["Pre-Mint",["Запуск сайту, Discord, Twitter","Верифікація благодійного фонду на авторитетній регульованій біржі криптовалюти для отримання зібраних коштів на рахунок благодійного фонду.","Активна маркетингова кампанія для забезпечення успішності нашого проекту","Розіграш безкоштовних NFT серед учасників нашої спільноти в  Discord та Twitter"]],
            ["Ambassadors",["Ми запрошуємо перших 100 амбасадорів до проєкту - компанії, відомі люди, інфлюєнсери, маркетологи, піарники та інші бджілки"]],
            ["Public Sale",["Публічний продаж починається 12.12.2022 (ціни від 1.11 Matic)","10 000 проданих NFT дозволять нам запустити благодійний marketplace для допомоги Україні."]],
            ["Charitable Purpose",["90% виручки від продажу буде передано в благодійний фонд “Українська Освітня Фундація” на закупівлю медикаментів та вітамінів для дітей в Україні.","10% виручки від продажу буде направлено на розвиток благодійної платформи Charity Hero  та розвиток інших колекцій, над якими ми вже працюємо."]],
            "Ця дорожня карта окреслює цілі, які ми хочемо досягти. У нас є багато ідей щодо розвитку нашої спільноти та концепцій, над якими ми працюємо. Ми розвиваємо проєкт та вдосконалюємо його з кожним етапом."
        ],
        "roadmapBlock":[
            [
                [bee0,"КРОК ПЕРШИЙ","Завантажте та налаштуйте Metamask. Metamask — це безпечна програма, яка може згенерувати гаманець Polygon ( Matic), зберігати ваші Matic (або криптовалюти на основі ETH), а також ваші NFT та дозволяти вам здійснювати транзакції в блокчейні Polygon ( Matic).","Metamask","https://metamask.io/"],
                [bee1,"КРОК ДРУГИЙ","Щоб купити Ukrainian Bee NFT, вам потрібно буде додати Matic у свій гаманець. Це можна зробити за допомогою обміну чи брокера, такого як Coinbase чи Binance, щоб конвертувати вашу фіатну валюту (USD/EUR/GBP тощо) в Matic ( Polygon), а потім вивести Matic (Polygon) у свій гаманець на Metamask.","Coinbase","https://www.coinbase.com/"],
                [bee2,"КРОК ТРЕТІЙ",`Ви можете придбати Українських бджіл натиснувши кнопку “Mint” нижче. Публічний продаж починається 12.12.2022 (ціни від 1.11 Matic). Після мінтингу колекція буде доступна на платформі  OpenSea.io. Кожна нфт буде надавати доступ до різних закритих можливостей в нашому коммюніті.`,"Mint",,"mint"],
            ]
        ]
    }
}

function RoadmapWay(){
    const [leng, setLeng] = useState("en")
    useEffect(()=>{
        
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
    })

    return(
        <div className="path_way way">
            <div className="way_cell cell cell0">
                <div className="cell_info">
                    <ul>
                        {
                            lengArr[leng]["roadmap"][0][1].map((el)=>{
                                return(
                                    <li key={el}>{el}</li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="cell_center">
                    <img src={Flower0} alt="" />
                    <img src={Rectangle} alt="" />
                </div>
                <div className="cell_numb"><span>{lengArr[leng]["roadmap"][0][0]}</span></div>
            </div>
            <div className="way_cell cell cell1">
                <div className="cell_numb"><span>{lengArr[leng]["roadmap"][1][0]}</span></div>
                <div className="cell_center">
                    <img src={Flower0} alt="" />
                    <img src={Rectangle} alt="" />
                </div>
                <div className="cell_info">
                    <ul>
                        {
                            lengArr[leng]["roadmap"][1][1].map((el)=>{
                                return(
                                    <li key={el}>{el}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="way_cell cell cell0">
                <div className="cell_info">
                    <ul>
                        {
                            lengArr[leng]["roadmap"][2][1].map((el)=>{
                                return(
                                    <li key={el}>{el}</li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="cell_center">
                    <img src={Flower0} alt="" />
                    <img src={Rectangle} alt="" />
                </div>
                <div className="cell_numb"><span>{lengArr[leng]["roadmap"][2][0]}</span></div>
            </div>
            <div className="way_cell cell cell1">
                <div className="cell_numb"><span>{lengArr[leng]["roadmap"][3][0]}</span></div>
                <div className="cell_center">
                    <img src={Flower0} alt="" />
                    <img src={Rectangle} alt="" />
                </div>
                <div className="cell_info">
                    <ul>
                        {
                            lengArr[leng]["roadmap"][3][1].map((el)=>{
                                return(
                                    <li key={el}>{el}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}


// function PopUp(){
//     const [actPop, setActPop] = useState(false)

//     useEffect(
//         ()=>{        
//             const Buttun = document.querySelector('#mintroad')

//             Buttun.addEventListener('click',()=>{
//                 setActPop(true)
//                 console.log(actPop)
//             })

//             const interval = setInterval(() => {
//                 setActPop(false)
//                 console.log(actPop)
//             }, 1500);
//             return () => clearInterval(interval);
//         },
//     )

//     return(
//         <div className={actPop? "pop-up-donate pop-up-mint pop-up-donate_act right":"pop-up-donate pop-up-mint pop-up-donate_dis"}>
//             <span>Coming soon</span>
//         </div>
//     )
// }


export function RoadmapCell(){
    return(
        <div className="road">
            <span className="road_title title">Join the club</span>
            <div className="road_case">
                <RoadmapBlock></RoadmapBlock>
            </div>
            <span className="road_ps">

            </span>
            {/* <ButtonLocation>Join the club</ButtonLocation> */}
            <div className="road_border">
                
            </div>
        </div>
    )
}

function RoadmapBlock(){
    const [leng, setLeng] = useState("en")
    useEffect(()=>{
        
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
    })
    return(

        lengArr[leng]["roadmapBlock"].map((el)=>{
            
            return(
                el.map((el)=>{
                    return(
                        <div className="roadmap-block" key={el[0]}>
                            <img src={el[0]} alt="" />
                            <span className="roadmap-block_title">{el[1]}</span>
                            <span className="roadmap-block_info">{el[2]}</span>
                            <a href={el[4]} target="_blank" rel="noreferrer"><button id={el[5]}>{el[3]}</button></a>
                        </div>
                    )
                })
            )
        })
    )
}



export function Roadmap(){
    const [leng, setLeng] = useState("en")
    useEffect(()=>{
        
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
    })
    return(
        <div className="roadmap">
            <Ambassador></Ambassador>
            <span className="roadmap_title title">roadmap</span>
            <span className="roadmap_info" >{lengArr[leng]["roadmap"][4]}</span>
            <div className="roadmap_path path">
                <RoadmapWay></RoadmapWay>
            </div>
            <div className="roadmap_border">
                
            </div>
            
        </div>
    )
}


