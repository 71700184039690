import React,{useState,useEffect} from "react";
import { ButtonLocation } from "../../../button";
import "./join.scss"

const lengArr = {
    "en":{
        "join":[
            [
                ["STEP ONE","Download and configure Metamask. Metamask is a secure application that can generate an Ethereum (ETH) wallet, store your ETH (or ETH-based cryptocurrencies) as well as your NFTs, and allow you to make transactions on the Ethereum blockchain.","Metamask","https://metamask.io/"],
                ["STEP TWO","In order to get your Ukrainian Bee NFT you need to add Ethereum to your wallet. This can be done by using an exchange or broker like Coinbase to convert your fiat currency (USD/EUR/GBP etc.) to Ethereum and then withdraw the Ethereum to your wallet on Metamask.","Metamask","https://metamask.io/"],
                ["STEP THREE",`Register your wallet on the ambassador list so that you are included in the mint plan of your personal bee and you could receive it by completing the following steps.`,"Register","https://docs.google.com/forms/d/e/1FAIpQLSem4cMAwoDMD-N-pQOs3F32qj5ISJLK6a2mTkgnGOYYfWMSrQ/viewform"],
                ["КРОК ТРЕТІЙ",`You can get your bee at a special price by clicking the “Mint” button below. Public sale starts on 08/24/2022 (prices from 0.03 ETH). After minting, the collection will be available on the OpenSea.io platform. Each NFT will provide access to various special opportunities in our community.`,"Mint","#mint"],
            ]
        ]
    },
    "ua":{
        "join":[
            [
                ["КРОК ПЕРШИЙ","Завантажте та налаштуйте Metamask. Metamask — це безпечна програма, яка може згенерувати гаманець Ethereum (ETH), зберігати ваші ETH (або криптовалюти на основі ETH), а також ваші NFT та дозволяти вам здійснювати транзакції в блокчейні Ethereum. ","Metamask","https://metamask.io/"],
                ["КРОК ДРУГИЙ","Щоб отримати вашу  Ukrainian Bee NFT, вам потрібно буде додати Ethereum у свій гаманець. Це можна зробити за допомогою обміну чи брокера, такого як Coinbase, щоб конвертувати вашу фіатну валюту (USD/EUR/GBP тощо) в Ethereum, а потім вивести Ethereum у свій гаманець на Metamask. ","Metamask","https://metamask.io/"],
                ["КРОК ТРЕТІЙ",`Зареєструйте ваш гаманець у списку амбасадорів, щоб вас внесли в план на мінт вашої особистої бджоли і ви могли її отримати виконавши наступні кроки. Кнопка реєстрація.`,"Реєстрація","https://docs.google.com/forms/d/e/1FAIpQLSfYzTYssZvzI8n0bgYrnhA9Gq4-Z7uCsW0rqzEFXVWkl7UTuw/viewform"],
                ["крок четвертий",`Ви можете отримати вашу бджолу за спеціальною ціною натиснувши кнопку  “Mint” нижче. Публічний продаж починається 22.11.2022 (ціни від 0,03 ETH). Після мінтингу колекція буде доступна на платформі OpenSea.io. Кожна нфт буде надавати доступ до різних закритих можливостей в нашому коммюніті.`,"Mint","#mint"],
            ]
        ]
    }
}


export function JoinBlock(){
    const [leng, setLeng] = useState("ua")
    useEffect(()=>{
        
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
    })
    return(

        <div className="box-join">
            <span className="title-join">Join the club</span>
            <div className="join-case">
                {
                    lengArr[leng]["join"].map((el)=>{
                
                        return(
                            el.map((el)=>{
                                return(
                                    <div className="join" key={el[0]}>
                                        <span className="join_title">{el[0]}</span>
                                        <span className="join_info">{el[1]}</span>
                                        <a href={el[3]} target="_blank" rel="noreferrer"><button id={el[4]}>{el[2]}</button></a>
                                    </div>
                                )
                            })
                        )
                    })
                }
            </div>
            <ButtonLocation href="https://discord.gg/zRMSv3cAJR">Join on Discord</ButtonLocation>
        </div>
    )
}





