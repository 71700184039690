import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, useParams, BrowserRouter } from "react-router-dom";
import "./scss/media.scss"
import "./scss/mane.scss"
import { Header} from './jsx/header';
import { ArrowUp, BorderBee } from './jsx/modail';
import { AboutUs } from './jsx/about-us';
import { Roadmap, RoadmapCell } from './jsx/roadmap';
import { OurTeam } from './jsx/our-team';
import { FAQ } from './jsx/faq';
import { Footer } from './jsx/footer';
import { Spinr } from './jsx/spiner';
import { PopUpMint } from './jsx/pop-up-mint';
import { AmbasadorPage } from './jsx/ambasador-page/ambasador-page';
import { TeamPage } from './jsx/ambasador-page/team-page';

const MainApp = () =>{
  return(
    <div>
      <PopUpMint/>
      <Spinr></Spinr>
      <Header></Header>
      <BorderBee></BorderBee>
      <AboutUs></AboutUs>
      <Roadmap></Roadmap>
      <RoadmapCell></RoadmapCell>
      <OurTeam></OurTeam>
      <FAQ></FAQ>
      <Footer></Footer>
      <ArrowUp></ArrowUp>
    </div>
  )
}


const root = ReactDOM.createRoot(document.getElementById('body-website'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp />}></Route>
        <Route path="/ambasador-mint" element={<AmbasadorPage/>}></Route>
        <Route path="/team-mint" element={<TeamPage/>}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

