import React from "react";
import "../scss/our-team.scss"
import AD from "../img/ourteam/AlexDiachenko.png"
import AM from "../img/ourteam/AndriyMykhaylov.png"
import BI from "../img/ourteam/BronislavIvachshenko.png"
import DY from "../img/ourteam/DenisYedin.png"
import DN from "../img/ourteam/DmytroNovokrestov.png"
import LR from "../img/ourteam/LiubovRomanova.png"
import MS from "../img/ourteam/MariaSatsiuk.png"
import RS from "../img/ourteam/RomanStarikov.png"
import TL from "../img/ourteam/TatianaLiulka.png"
import AK from "../img/ourteam/ArtemKurikhin.png"
import AH from "../img/ourteam/AndriiHaliamin.png"
import OB from "../img/ourteam/OleksiyBee.png"
import Facebook from "../img/ourteam/sot/facebook.png"
import Linkin from "../img/ourteam/sot/linkin.png"
import SotFreim from "../img/ourteam/sot/sot.svg"
import Inst from "../img/ourteam/sot/Instagram.png"
import G from "../img/ourteam/sot/greensot.png"
import O from "../img/ourteam/sot/olivesot.png"
import OR from "../img/ourteam/sot/orengsot.png"
import P from "../img/ourteam/sot/purpelsot.png"
import V from "../img/ourteam/sot/veoletsot.png"
import R from "../img/ourteam/sot/redsot.png"
import { Hcih } from "./how-can-i-help";



const TeamBEE = [
    [LR,"Liubov Romanova","Creative and design Bee","pink",Inst,P,"https://www.instagram.com/loveromanova_design/"],
    [AD,"Alex Diachenko","Charity Hero and communications Bee","green",Facebook,G,'https://www.facebook.com/alexdiach'],
    [RS,"Roman Starikov","Technology Bee","violet",Facebook,V,"https://www.facebook.com/starikovrom"],
    [TL,"Tatiana Liulka","Head of the charity fund Bee","olive",Facebook,O,'https://www.facebook.com/profile.php?id=100001101838071'],
    [AM,"Andriy Mykhaylov","Eco activist Bee","red",Facebook,R,'https://www.facebook.com/andriy.mikhaylov.sh'],
    [DY,"Denis Yedin","Crypto and economy Bee","orange",Linkin,OR,'https://www.linkedin.com/in/denisyedin'],
    [AK,"Artem Kurikhin","Journalist Bee","green",Facebook,G,'https://www.facebook.com/artem.kuryhin'],
    [MS,"Maria Satsiuk","Сreativity and communication Bee","pink",Facebook,P,'https://www.facebook.com/maria.satsiuk'],
    [BI,"Bronislav Ivachshenko","Frontend developer Bee","violet",Facebook,V,],
    [DN,"Dmytro Novokrestov","Volunteer Bee","olive",Facebook,O,'https://www.facebook.com/profile.php?id=100006702917271'],
    [AH,"Andrii Haliamin","Programmer Bee","red",Facebook,R,'https://www.facebook.com/galandnik'],
    [OB,"Oleksiy Bee","Backend developer Bee","orange",SotFreim,OR,''],
]



function Persone(){
    return(
        TeamBEE.map((el)=>{
            return(
                <div className="persone" key={el[1]}>
                    <img className="persone_img" src={el[0]} alt="" />
                    <div className={`persone-inf `}>
                        <div className="persone-inf_faq">
                            
                            <a className="persone-inf_logo" href={el[6]}>
                                    <img src={el[5]} alt="" />
                                    <img src={el[4]} alt="" />
                                </a>
                            <div className="case">
                                <span className="case_name">{el[1]}</span>
                                <div className={`${el[3]}`}><span>{el[2]}</span></div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        })
    )
}

export function OurTeam(){
    return(
        <div className="our-team">
            <Hcih></Hcih>
            <span className="our-team_title title">Our team</span>
            <div className="our-team_conreiner">
                <Persone></Persone>
            </div>
        </div>
    )
}
