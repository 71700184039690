import React, {useState} from "react";
//import { ConnectButton } from '@rainbow-me/rainbowkit';
import BeeConnectButton from "../components/BeeConnectButton";
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import "@rainbow-me/rainbowkit/styles.css";
import MintSpecialButton from "../components/MintSpecialButton";
import PriceDisplay from "../components/PriceDisplay";
import MintedNFTDisplay from "../components/MintedNFTDisplay";

const { chains, provider, webSocketProvider } = configureChains(
    [
      chain.polygon
    ],
    [
      alchemyProvider({ apiKey: 'TxJ8QgKl3QhUeAYicPtLNd-433-oEl8D' }),
      publicProvider(),
    ]
  );
  
  const { connectors } = getDefaultWallets({
    appName: 'Bee',
    chains,
  });
  
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
  });

const contractAddress = '0x9DC739044e634400221D4d5276f3a7DF3744d7D8'

export default function MintA() {
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false)

  return (
    <React.StrictMode>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          {/* <PriceDisplay 
              contractAddress={contractAddress}
          /> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                    <MintedNFTDisplay id={id} loading={loading}/>
              </div>
          <BeeConnectButton />
          <MintSpecialButton
              contractAddress={contractAddress}
              setId={setId}
              loading={loading}
              setLoading={setLoading}
          />
        </RainbowKitProvider>
      </WagmiConfig>
    </React.StrictMode>
  );
}