import React, {useState, useEffect} from 'react';
import SmartContract from "../contracts/BeeContract1155.json";
import { useContract, useAccount, useProvider } from 'wagmi'

import sot0 from "../img/spiner/sot0.svg"
import sot1 from "../img/spiner/sot1.svg"
import sot2 from "../img/spiner/sot2.svg"
import sot3 from "../img/spiner/sot7.svg"
import sot4 from "../img/spiner/sot4.svg"
import sot5 from "../img/spiner/sot5.svg"
import sot6 from "../img/spiner/sot3.svg"

import "../scss/spiner.scss"

const LengArray = {
  "en":{
    "mint-act":"Click the button and create a magical charity bee.",
    "mint-wait":"The birth process takes some time, please wait.",
  },

  "ua":{
    "mint-act":"Натисни кнопку та створи свою чарівну благодійну бджолу.",
    "mint-wait":"Процес народження займає трохи часу, почекайте.",
  }
}

const MintedNFTDisplay = ({id, loading}) => {
    const [url, setUrl] = useState(null);
    const [leng, setLeng] = useState("en")

    useEffect(()=>{
      const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
    })

    const fetchData = async () => {
      console.log(id)
      const res = await fetch(`https://mint.bee-ua.pages.dev/api/nft/${id}`);
      const data = await res.json();
      setUrl(data.image);
    }

    useEffect(()=>{
      if(id!=0){
        console.log('loading')
        fetchData();
      }

    },[id])

    if(loading) return (
      <div style={{
        position:"relative"
      }}>
          <div style={{
            position:"relative",
            width:"200px",
            height:"200px",
            top:"-10px"
          }}
          
          >
            <div className="spiner_case case-spiner">
                <div>
                  <img className="case-spiner_sot0" src={sot0} alt="sot" />
                  <img className="case-spiner_sot1" src={sot1} alt="sot" />
                </div>
                <div>
                  <img className="case-spiner_sot2" src={sot2} alt="sot" />
                  <img className="case-spiner_sot3" src={sot3} alt="sot" />
                  <img className="case-spiner_sot4" src={sot4} alt="sot" />
                </div>
                <div>
                  <img className="case-spiner_sot5" src={sot5} alt="sot" />
                  <img className="case-spiner_sot6" src={sot6} alt="sot" />
                </div>
            </div>
            <div className='text'>{LengArray[leng]["mint-wait"]}</div>
          </div>
      </div>
    )

    if(id==0 || url == null) return (<div className='text'>{LengArray[leng]["mint-act"]}</div>)

    return (
        <img src={url}  style={{width: 215, height: 215}}/>
      );

}

export default MintedNFTDisplay
