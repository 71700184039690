import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./geks.scss";

const lengArr = {
    "en":{
        "title":"Other ways to help",
        "geks":[
            ["Tell your friends"," about our project by posting"," on social networks", " about the importance of supporting children"],
            ["Suggest"," your company to support","children with medicine"],
            [" Invite a familiar"," journalist, star, or influencer","to support"," our"," project"],
            ["Organise an auction"," with your personal bee NFT"],
            [" Buy medicine"," and give it to our bees-volunteers"],
            ["Make a donation"," on our website"]
        ]
    },
    "ua":{
        "title":"Як ви можете ще допомогти?",
        "geks":[
            ["Розповісти друзям"," про наш проєкт, написавши пост"," у соціальних мережах", " про важливість підтримки дітей"],
            ["Запросити"," вашу компанію підтримати","дітей ліками"],
            ["Запросити знайомого"," журналіста, зірку, або інфлюєнсера ","підтримати"," наш"," проєкт"],
            ["Провести аукціон"," з вашою бджолою"],
            ["Купити ліки"," та передати нашим бджілкам"],
            ["Зробити донейт"," на нашому сайті"]
        ]
    }
}



export function Geks(){
    const [leng,setLeng] = useState("ua")

    useEffect(()=>{ 
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
        const geks = Array.prototype.slice.call(document.querySelectorAll(".geks"))

        geks.map((el)=>{
            let trig = false
            Array.prototype.slice.call(el.children).map((el)=>{
                if(!trig){
                    el.classList.add("bold")
                    trig=!trig
                }else{
                    trig=!trig
                }
            })
        })

    },[])

    return(
        <div className="geks-display">
            <span className="geks-title">{lengArr[leng]["title"]}</span>
            <div className="geks-box">
                <div className="first-line">
                    <div className="geks g-vialet">
                        {
                            lengArr[leng]["geks"][0].map((el)=>{
                                return(
                                    <span className="geks_text">{el}</span>
                                )
                            })
                        }
                    </div>
                    <div className="geks g-blue">
                        {
                            lengArr[leng]["geks"][1].map((el)=>{
                                return(
                                    <span className="geks_text">{el}</span>
                                )
                            })
                        }
                    </div>
                    <div className="geks g-green">
                        {
                            lengArr[leng]["geks"][2].map((el)=>{
                                return(
                                    <span className="geks_text">{el}</span>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="midle-line">
                    <div className="geks g-pink">
                    {
                            lengArr[leng]["geks"][3].map((el)=>{
                                return(
                                    <span className="geks_text">{el}</span>
                                )
                            })
                        }
                    </div>
                    <div className="geks g-red">
                        {
                            lengArr[leng]["geks"][4].map((el)=>{
                                return(
                                    <span className="geks_text">{el}</span>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="geks g-yellow botton-line">
                        {
                            lengArr[leng]["geks"][5].map((el)=>{
                                return(
                                    <span className="geks_text">{el}</span>
                                )
                            })
                        }
                </div>
            </div>
        </div>
    )
}