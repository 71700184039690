import React from 'react';
import Web3 from "web3";
import SmartContract from "../contracts/BeeContract1155.json";
import { useAccount,useProvider, useNetwork, useContract, useSigner } from 'wagmi'
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";


const MintSpecialButtonTeam = ({contractAddress, setId, loading, setLoading}) => {
    const { address} = useAccount()
    const { data: signer } = useSigner()
    const provider = useProvider()
    const { chain, chains } = useNetwork()
    const contract = useContract({
        addressOrName: contractAddress,
        contractInterface: SmartContract.abi,
        signerOrProvider: signer
    })

    async function getRevertReason(txHash){
        var hash;
        try{
            let web3 = new Web3("https://polygon-mumbai.g.alchemy.com/v2/RElb-FfFegtPzqWYBZRdmgiIq1pp6F-L")
            const tx = await web3.eth.getTransaction(txHash)
        
            console.log(tx)
            var result = await web3.eth.call(tx, tx.blockNumber)
            console.log(result)
        
            result = result.startsWith('0x') ? result : `0x${result}`
        
            if (result && result.substr(138)) {
        
            const reason = web3.utils.toAscii(result.substr(138))
            console.log('Revert reason:', reason)
            return reason
        
            } else {
        
            console.log('Cannot get reason - No return value')
        
            }
        } catch(error) {
            console.log(error.message)
            setLoading(false);
            alert(error.message)
        }
    }

    const mint = async () => {
        var hash;
        setId(0);
        setLoading(true);
        try {
            const price = await contract.teamPrice();
            console.log(price);
            console.log(Web3.utils.toWei((price/10**18 * 1).toString(), "ether"))
            const response = await contract.mintSpecial({
                gasLimit: "285000",
                from: address,
                value: price,
            });
            console.log(response)
            hash = response.hash;

            const receipt = await response.wait();
            console.log(receipt)
            const event = receipt.events.filter(x => x?.event == "tokenidEvent");
            const bnumber = event[0].args[`_id`];
            setId(Number(bnumber));
            setLoading(false);

        } catch (error) {
            const parsedEthersError = getParsedEthersError(error);
            console.log(parsedEthersError)
            getRevertReason(hash)
        }

    }

    async function handleClick () {
            mint();
    }

    if(loading) return(<div/>)

    return (
        <div>
        {(address && !chain.unsupported) ? (<button className='button-yello-mint' onClick={()=> handleClick ()} disabled={!address}>Mint</button>)
        : (<div></div>)}
        </div>
      );
}

export default MintSpecialButtonTeam;