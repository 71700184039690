import React, { useEffect } from "react"
import { useState } from "react"

import "../scss/pop-up-mint.scss"
import Mint from "./mint"

import Logo from "../img/mint/logo_full.svg"
import Bee from "../img/mint/bee.png"
import Kalina from "../img/mint/Kalina.png"
import Hive from "../img/mint/hive.png"
import Klinavinok from "../img/mint/Klinavinok.png"
import { SosialBox } from "../components/SosialMedia"

export function PopUpMint(){
    const [actPop, setActPop] = useState(false)

    useEffect(
        ()=>{        
            const Buttun = Array.prototype.slice.call(document.querySelectorAll(`#mint`))

            Buttun.forEach((el)=>{
                 el.addEventListener('click',()=>{
                     setActPop(true)
                 })
             })

            document.addEventListener("keydown", (el)=>{
                if(el.key === "Escape"){
                    setActPop(false)
                }
            })

            document.querySelector("#closeMint").addEventListener("click",()=>{
                setActPop(false)
            })

            document.addEventListener("click", (el)=>{
                el.path.map((el)=>{
                    if(actPop === true){
                        if(el.className !== "pop-up-mint pop-up-mint_act"){
                            setActPop(false)
                        }else{

                        }
                    }else{
                    }
                })
            } )
        },[]
    )

    return(
        <div className={actPop? "pop-up-mint pop-up-mint_act":"pop-up-mint_dis"}>
            <div className="burger mint-x" id="closeMint">
                <div className="burger_top burger_top__act"></div>
                <div className="burger_bottom burger_bottom_act"></div>
            </div>
            <div className="box-mint-case">
                <Mint/>
            </div>
            <SosialBox></SosialBox>
            <div className="bg-mint"/>
            <img src={Logo} alt="" className="logo-mint"/>
            <img src={Bee} alt="" className="bee-mint"/>
            <img src={Kalina} alt="" className="kalina-mint"/>
            <div className="img-hive-box">
                <img src={Hive} alt="" className="hive-mint"/>
                <img src={Klinavinok} alt="" className="klinavinok-mint"/>
            </div>
        </div>
    )
}

