import React, { useEffect, useState } from "react";
import "../scss/modail.scss"

import Arrow from "../img/modail/arow.svg"
import Token from "../img/modail/Vector.png"
import Flower from "../img/modail/flower_1.png"
import b1 from "../img/modail/bee/frame(1).png"
import b2 from "../img/modail/bee/frame(2).png"
import b3 from "../img/modail/bee/frame(3).png"
import b4 from "../img/modail/bee/frame(4).png"
import b5 from "../img/modail/bee/frame(5).png"
import b6 from "../img/modail/bee/frame(6).png"
import b7 from "../img/modail/bee/frame(7).png"
import b8 from "../img/modail/bee/frame(8).png"
import b9 from "../img/modail/bee/frame(9).png"
import b10 from "../img/modail/bee/frame(10).png"
import b11 from "../img/modail/bee/frame(11).png"
import b12 from "../img/modail/bee/frame(12).png"
import b13 from "../img/modail/bee/frame(13).png"
import b14 from "../img/modail/bee/frame(14).png"
import b15 from "../img/modail/bee/frame(15).png"
import b16 from "../img/modail/bee/frame(16).png"
import b17 from "../img/modail/bee/frame(17).png"

const arrBee0 = [b1,b2,b3,b4,b5,b6,b7,b8,b9]
const arrBee1 = [b10,b11,b12,b13,b14,b15,b16,b17]

export function BorderBee(){
    const [beeL, setBeeL] = useState(false)
    const [beeArray0, setBeeArray0 ] = useState(arrBee0)
    const [beeArray1, setBeeArray1 ] = useState(arrBee1)

    useEffect(()=>{
        const interval = setInterval(() => {
            setBeeL(!beeL)
        }, 1000);

        const intervalArr = setInterval(()=>{
            let lpArray0 = beeArray0
            lpArray0.push(beeArray0[0])
            lpArray0.shift()
            setBeeArray0(lpArray0)
            let lpArray1 = beeArray1
            lpArray1.push(beeArray1[0])
            lpArray1.shift()
            setBeeArray1(lpArray1)
        },1000)
          

        return () => {
            clearInterval(interval);
            clearInterval(intervalArr);
        }
    })

    return(
        <div className="bee-line">
            <div className={beeL? "bee-line-case bee-line-case__beack " : "bee-line-case bee-line-case__beack bee-line-case__dis"}>
                <div className="bee-line_leir0">
                    {
                        beeArray0.map((el)=>{
                            return(
                                <img src={el} alt="" key={el} />
                            )
                        })
                    }
                    {
                        beeArray0.map((el)=>{
                            return(
                                <img src={el} alt="" key={el} />
                            )
                        })
                    }
                </div>
                <div className="bee-line_leir1">
                {
                        beeArray1.map((el)=>{
                            return(
                                <img src={el} alt="" key={el}/>
                            )
                        })
                    }
                    {
                        beeArray1.map((el)=>{
                            return(
                                <img src={el} alt="" key={el}/>
                            )
                        })
                    }
                </div>
            </div>
            <div className={!beeL? "bee-line-case" : "bee-line-case bee-line-case__dis"}>
                <div className="bee-line_leir0">
                    {
                        beeArray1.map((el)=>{
                            return(
                                <img src={el} alt="" key={el} />
                            )
                        })
                    }
                    {
                        beeArray1.map((el)=>{
                            return(
                                <img src={el} alt="" key={el} />
                            )
                        })
                    }
                </div>
                <div className="bee-line_leir1">
                {
                        beeArray0.map((el)=>{
                            return(
                                <img src={el} alt="" key={el}/>
                            )
                        })
                    }
                    {
                        beeArray0.map((el)=>{
                            return(
                                <img src={el} alt="" key={el}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export function FlowerToken(prop){
    return(
        <div className="flower-token">
            <img src={Flower} alt="" key={Flower}/>
            <span className="flower-token_title">{prop.children[0]}</span>
            <span>{prop.children[1]}</span>
        </div>
    )
}

export function City(prop){
    return(
        <div className="city-token">
            <img className="city-token_img" src={Token} alt="" />
            <span className="city-token_title">{prop.children}</span>
        </div>
    )
}


export function ArrowUp(){
    const [actUp, setActUp] = useState(false)
    const [top, stateTop] = useState(0);
    const [scroll, stateScroll] = useState(0)
    

    useEffect(()=>{

        document.addEventListener("scroll", ()=>{
            
            stateTop(window.pageYOffset)
            stateScroll(window.pageYOffset - top)

            if(top >= 1000){
                if(scroll <= 0){
                    setActUp(false)
                }else if(scroll >= 0){
                    setActUp(true)
                }
            }else if(top <= 1000){
                setActUp(false)
            }
        })        
    })

    function scrollToE(){
        const location = document.querySelector(`header`)
        location.scrollIntoView({behavior:"smooth"})
    }
    return(
        <img src={Arrow} alt="up" className={actUp? "arrow-up": "arrow-up arrow-up_dis"} onClick={()=>{
            scrollToE()
        }}/>
    )
}