import React,{ useState,useEffect }from "react";
import MintA from "../mintA.jsx";
import "./ambasador-page.scss"
import "../../scss/pop-up-mint.scss"

import Logo from "../../img/mint/logo_full.svg"
import Bee from "../../img/mint/bee.png"
import Kalina from "../../img/mint/Kalina.png"
import Hive from "../../img/mint/hive.png"
import Klinavinok from "../../img/mint/Klinavinok.png"
import { SosialBox } from "../../components/SosialMedia"
import { JoinBlock } from "./component/join/join.jsx";
import { Geks } from "./geks/geks.jsx";
import { ButtonLeng } from "../button.jsx";

const lengArr = {
    "en":{
        "title":["We are grateful to you for becoming an ambassador for the children of Ukraine!","On this page you can get your personal bee."]
    },
    "ua":{
        "title":["Ми вдячні вам, що ви стали амбасадором дітей України!", "На цій сторінці ви зможете отримати свою особисту бджолу."]
    }
}

export function MintAmb(){
    const [actPop, setActPop] = useState(true)
    const [leng, setLeng] = useState("ua")

    useEffect(
        ()=>{        
            const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
            LengButtun.forEach((el)=>{
                el.addEventListener("click",(el)=>{
                    setLeng(el.target.id)
                })
            })
        },[]
    )

    return(
        <div className="mint-amb">
            <div className="bg">
                <div className="bg-mint-amb"/>
                <div className="bg-bot-mint-amb"/>
            </div>
            <div className="amb-case">
                <div className="top-box">
                    <img src={Logo} alt="" className="logo-mint-amb"/>
                    <div className="header-box">
                        <div className="header_leng">
                            <ButtonLeng id="ua"/>
                            <ButtonLeng id="en"/>
                        </div>
                        <SosialBox></SosialBox>
                    </div>
                    
                </div>
                <img src={Bee} alt="" className="bee-mint-amb"/>
                <img src={Kalina} alt="" className="kalina-mint-amb"/>
                <a name="mint"></a>
                <div className="box-mint-case-amb" name="mint">
                    <span className="box-mint-case-amb_title">{lengArr[leng]["title"][0]}</span>
                    <span className="box-mint-case-amb_inf">{lengArr[leng]["title"][1]}</span>
                    <MintA/>
                </div>
                <div className="img-hive-box-amb">
                    <img src={Hive} alt="" className="hive-mint-amb"/>
                    <img src={Klinavinok} alt="" className="klinavinok-mint-amb"/>
                </div>
            </div>
        </div>
    )
}


export function AmbasadorPage (){

    return(
        <div className="ambasador-page">
            <MintAmb></MintAmb>
            <JoinBlock></JoinBlock>
            <Geks></Geks>
        </div>
    )
}

