import React from "react";
import * as clipboard from "clipboard-polyfill/text";
import { useState } from "react";
import { useEffect } from "react";
import "../scss/how-can-i-help.scss"
import bee1 from "../img/how-can-i-help/bee1.png"
import bee2 from "../img/how-can-i-help/bee2.png"
import bee3 from "../img/how-can-i-help/bee3.png"
import donat from "../img/how-can-i-help/donat.png"
import hend from "../img/how-can-i-help/hend.png"
import nft from "../img/how-can-i-help/nft.png"
import close from "../img/how-can-i-help/close.svg"
import copi from "../img/how-can-i-help/copi.svg"
import Mint from "./mint";
// import { PopUpMint } from "./pop-up-mint";



const Leng = {
    "ua":{
        "title": "How can I help?",
        "sub-title":"Не готові придбати нфт? Але хочете допомогти дітям України? Зробіть донейт в 1 клік на покупку ліків та вітамінів.",
        "how-donation":[
            ['1',"Придбати NFT з нашої колекції","MINT"],
            ['2',"Зробити донейт в криптовалюті","DONATE"],
            ['3',"Зробити донейт в один клік","DONATE"],
        ],
        "ps":"",
        "cript":[
            'Криптовалюта',
            'BTC (Taproot) Donate',
            'bc1pzmwd79ns3cwtt2zj3ltu0279l7kahvuw4828nv8ay3msq0ku0rrs3ex25f',
            'ETH and ERC20  Donate',
            '0x7e058807CED2593423ed0E2aFeA805A72305e139'
        ],
        "copi":"Cкопійовано до буфера"
    },
    "en":{
        "title": "How can I help?",
        "sub-title":"Not ready to buy NFT but want to help Ukrainian children? Make 1 click donation for medicines and vitamins.",
        "how-donation":[
            ['1',"Buy NFT from our collection","MINT"],
            ['2',"Make donation in cryptocurrency","DONATE"],
            ['3',"Make 1 click donation","DONATE"],
        ],
        "ps":"",
        "cript":[
            'cryptocurrency',
            'BTC (Taproot) Donate',
            ['bc1pzmwd79ns3cwtt2zj3ltu0279l7kahvu','w4828nv8ay3msq0ku0rrs3ex25f'],
            'ETH and ERC20  Donate',
            ['0x7e058807CED2593423','ed0E2aFeA805A72305e139']
        ],
        "copi":"Copied to clipboard"
    },
}

function PopUaDonate(){
    const [L, setL] = useState("en")
    const [actPop, setActPop] = useState(false)
    const [actCopi, setActCopi] = useState(false)

    const SwitchA = ()=>{
        setActCopi(true)
        setTimeout(()=>{
            setActCopi(false)
        },1000)
    }

    useEffect(()=>{
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setL(el.target.id)
            })
        })

        document.querySelector("#pop-up-act").addEventListener("click",()=>{
            setActPop(!actPop)
        })
    })

    return(
        <div className={actPop? "pop-up-donate pop-up-donate_act":"pop-up-donate pop-up-donate_dis"}>
            <button className="pop-up-donate_close" onClick={()=>{setActPop(!actPop)}}><img  src={close} alt="" /></button>
            <span className="pop-up-donate_title">{Leng[L]["cript"][0]}</span>
            <div>
                <span className="pop-up-donate_subtitle">{Leng[L]["cript"][1]}</span>
                <div className="span-case">
                    <span className="pop-up-donate_cripta-copi">{Leng[L]["cript"][2][0]}</span>
                    <span className="pop-up-donate_cripta-copi">{Leng[L]["cript"][2][1]}</span>
                    <img src={copi} onClick={()=>{clipboard.writeText(`${Leng[L]['cript'][2][0]+Leng[L]['cript'][2][1]}`);SwitchA()}} alt=""/>
                </div>
            </div>
            <div>
                <span className="pop-up-donate_subtitle">{Leng[L]["cript"][3]}</span>
                <div className="span-case">
                    <span className="pop-up-donate_cripta-copi">{Leng[L]["cript"][4][0]}</span>
                    <span className="pop-up-donate_cripta-copi">{Leng[L]["cript"][4][1]}</span>
                    <img src={copi} onClick={()=>{clipboard.writeText(`${Leng[L]['cript'][4]}`);SwitchA()}} alt="" />
                </div>
            </div>
            <div className={actCopi? "copi-modail " : "copi-modail copi-modail_dis"}>
                <span>{Leng[L]["copi"]}</span>
            </div>
        </div>
    )
}

export function Hcih (){
    const [leng, setLeng] = useState("en")

    useEffect(()=>{
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
    })
       
    return(
        <div className="hcih">
            
            <PopUaDonate></PopUaDonate>
            <span className="hcih_title title"> {Leng[leng]["title"]}</span>
            <span className={ leng==="en"? "hcih_subtitle hcih_subtitle0" : "hcih_subtitle"}>{Leng[leng]["sub-title"]}</span>
            <div className="row">
                <div className="danat-case">

                    <div className="danat-case_title">
                        <span className="danat-case_caunt">{Leng[leng]["how-donation"][0][0]}</span>
                        <span>{Leng[leng]["how-donation"][0][1]}</span>
                    </div>
                    <img className="danat-case_img" src={bee1} alt="" />
                    <a rel="noreferrer" target="_blank" className="danat-case_button" id="mint">
                        <button><img src={nft} alt="" />{Leng[leng]["how-donation"][0][2]}</button>
                    </a>
                </div>
                <div className="danat-case">
                    <div className="danat-case_title">
                        <span className="danat-case_caunt">{Leng[leng]["how-donation"][1][0]}</span>
                        <span>{Leng[leng]["how-donation"][1][1]}</span>
                    </div>
                    <img className="danat-case_img" src={bee3} alt="" />
                    <div href=""className="danat-case_button">
                        <button id="pop-up-act"><img src={hend} alt="" />{Leng[leng]["how-donation"][1][2]}</button>
                    </div>
                </div>
                <div className="danat-case">
                    <div className="danat-case_title">
                        <span className="danat-case_caunt">{Leng[leng]["how-donation"][2][0]}</span>
                        <span>{Leng[leng]["how-donation"][2][1]}</span>
                    </div>
                    <img className="danat-case_img" src={bee2} alt="" />
                    <div className="danat-case_button">
                       <a href="https://beeua-donate.charityhero.live/"><button><img src={donat} alt="" />{Leng[leng]["how-donation"][2][2]}</button></a> 
                       {/* <button><img src={donat} alt="" />{Leng[leng]["how-donation"][2][2]}</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
