import React, { useEffect, useState } from "react";

import "../scss/spiner.scss"

import sot0 from "../img/spiner/sot0.svg"
import sot1 from "../img/spiner/sot1.svg"
import sot2 from "../img/spiner/sot2.svg"
import sot3 from "../img/spiner/sot7.svg"
import sot4 from "../img/spiner/sot4.svg"
import sot5 from "../img/spiner/sot5.svg"
import sot6 from "../img/spiner/sot3.svg"


export function Spinr(){
    const [dis, setDis] = useState(false)
    useEffect(()=>{
        setTimeout(()=>{setDis(true)},2000)
        // window.addEventListener("load", ()=>{console.log(1)})
        // window.addEventListener("", ()=>{console.log(2)})
    })
    return(
        <div className={!dis? "spiner":"spiner spiner__dis"}>
            <div className="spiner_bg"></div>
            <div className="spiner_case case-spiner">
                <div>
                    <img className="case-spiner_sot0" src={sot0} alt="sot" />
                    <img className="case-spiner_sot1" src={sot1} alt="sot" />
                </div>
                <div>
                    <img className="case-spiner_sot2" src={sot2} alt="sot" />
                    <img className="case-spiner_sot3" src={sot3} alt="sot" />
                    <img className="case-spiner_sot4" src={sot4} alt="sot" />
                </div>
                <div>
                    <img className="case-spiner_sot5" src={sot5} alt="sot" />
                    <img className="case-spiner_sot6" src={sot6} alt="sot" />
                </div>
            </div>
        </div>
    )
}